/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import api from '@services/api';

async function fetchCompanies(): Promise<any> {
  const empresaId = process.env.NEXT_PUBLIC_EMPRESA_ID;

  const responseData = await api
    .get(`empresas/getEmpresasGrupo/${empresaId}`)
    .then((res: any) => {
      return res.data || [];
    });

  console.log('responseData => ', responseData);
  return responseData;
}

export default fetchCompanies;
